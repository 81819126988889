module.exports = {
  defaultTitle: 'Dominic Farquharson',
  logo: 'https://www.farquharson.com/favicon/favicon-512.png',
  author: 'Dominic Farquharson',
  url: 'https://www.dfarquharson.com/',
  job: {
    position: 'Frontend Engineer',
    company: 'Flowcode'
  },
  defaultDescription: 'I’m Dominic and I’m a FullStack Engineer!',
  socialLinks: {
    github: 'https://github.com/dominic-farquharson',
    linkedin: 'https://www.linkedin.com/in/dominic-farquharson/',
  },
  googleAnalyticsID: 'UA-92365571-1',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  foundingDate: 2017,
};
