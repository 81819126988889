import React from "react";
import Helmet from "react-helmet";
import Thumbnail from "assets/thumbnail/thumbnail.png";
import {
	url,
	defaultDescription,
	defaultTitle,
} from "data/config";

export const Seo = ({
	title = defaultTitle,
	description = defaultDescription,
	location = "",
}) => {
	return (
		<Helmet>
			<meta name="description" content={description} />
			<meta name="image" content={Thumbnail} />

			<meta property="og:url" content={`${url}${location}/?ref=dfarquharson.com`} />
			<meta property="og:type" content="website" />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:image" content={Thumbnail} />

			<meta name="twitter:card" content="summary" />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:image:src" content={Thumbnail} />
			<title>{title}</title>
			<html lang="en" dir="ltr" />
		</Helmet>
	);
};
